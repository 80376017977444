
import { Emitter } from 'mitt';

import { defineComponent, inject, ref } from 'vue';
import MediaLib from './MediaLib.vue';
import { mdiCloseBox } from '@mdi/js';

export default defineComponent({
  props: {
    select: { type: Boolean, default: true },
    multiselect: { type: Boolean, default: false },
    libid: { type: String, required: true },
    type: { type: String, default: 'all' },
    category: { type: String, default: 'all' },
  },
  components: {
    MediaLib,
  },
  emits: ['confirm'],
  setup(props, { emit }) {
    const emitter = inject('emitter') as Emitter;
    const show = ref(false);

    const confirmSelection = (entries: Array<number>) => {
      emit('confirm', entries);
    };
    // eslint-disable-next-line
    emitter.on('MediaLibShow', (e) => {
      if (e == props.libid) {
        show.value = true;
      }
    });

    const closeModal = () => {
      show.value = false;
    };

    const confirmModal = () => {
      emitter.emit('MediaLibConfirmSelection');
      closeModal();
    };

    return {
      confirmSelection,
      confirmModal,
      mdiCloseBox,
      closeModal,
      show,
    };
  },
});
