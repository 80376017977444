
import { defineComponent, ref, PropType, watch } from 'vue';
import { mdiPlus, mdiBorderAllVariant, mdiDragVariant } from '@mdi/js';

import EntryText from './EntryText.vue';
import EntryMedia from './EntryMedia.vue';
import EntrySlider from './EntrySlider.vue';

export default defineComponent({
  components: {
    EntryText,
    EntryMedia,
    EntrySlider,
  },
  props: {
    row: { type: Object as PropType<EntryRow>, required: true },
    index: { type: Number },
  },
  setup(props, { emit }) {
    const element = ref(null);
    const width = ref(12);

    watch(props.row, () => {
      const { row } = props;
      if (row.cols.length == 1) {
        width.value = row.cols[0].width;
      }
    });

    watch(width, (oldval: number, newval: number) => {
      console.log(oldval, newval);
      if (oldval != newval) emit('colchange', { id: props.row.id, width: newval });
    });

    const del = () => {
      emit('delete', element);
    };

    const setType = (col: EntryCol, type: string) => {
      switch (type) {
        case 'text':
          col.type = 'text';
          col.typeconf = {
            text: '',
          };
          break;
        case 'media':
          col.type = 'media';
          col.typeconf = {
            id: -1,
            scaling: 0,
            width: '100%',
            height: 'auto',
            position: 'center',
            description: '',
          };
          break;
        case 'slider':
          col.type = 'slider';
          col.typeconf = {
            ids: [],
            height: 'auto',
            scaling: 0,
            position: 'center',
          };
          break;
        case 'none':
          col.type = 'none';
          col.typeconf = {
            id: -1,
          };
      }
    };
    return {
      element,
      del,
      mdiPlus,
      mdiBorderAllVariant,
      mdiDragVariant,
      setType,

      width,
    };
  },
});
