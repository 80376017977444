
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    entry: { type: Object as PropType<i18nEntry>, required: true },
    currentlang: { type: String as PropType<'de' | 'en'>, required: true },
  },
  emits: ['addlang', 'switchlang'],
  setup(props, { emit }) {
    const addLang = (lang: 'de' | 'en') => {
      emit('addlang', lang);
    };

    const switchlang = (lang: 'de' | 'en') => {
      emit('switchlang', lang);
    };

    return {
      addLang,
      switchlang,
    };
  },
});
