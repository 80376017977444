
import { defineComponent, computed, PropType, ref, watch, inject } from 'vue';
import { mdiPlus, mdiTextBoxMinus } from '@mdi/js';
import MediaLibButton from '../MediaLib/MediaLibButton.vue';
import Slider from '../../Slider.vue';
import SliderItem from '../../SliderItem.vue';
import { Emitter } from 'mitt';

export default defineComponent({
  props: {
    modelValue: { type: Object as PropType<EntryColSliderConfig>, required: true },
  },
  components: {
    MediaLibButton,
    Slider,
    SliderItem,
  },
  setup(props, { emit }) {
    const mitt = inject('emitter') as Emitter;

    const selected = computed({
      get: () => props.modelValue,
      set: (id: any) => emit('update:modelValue', id),
    });

    const confirm = (val: Array<MediaEntity>) => {
      selected.value.ids = val.map((entity: MediaEntity) => entity.id);
    };

    watch(
      [selected],
      () => {
        mitt.emit('resetSlider');
      },
      { deep: true }
    );

    return {
      selected,
      mdiPlus,
      confirm,
    };
  },
});
