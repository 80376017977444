
/* eslint-disable */
import { defineComponent, computed } from 'vue';
import Editor from '@tinymce/tinymce-vue';
import tinymce from 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/skins/ui/oxide/skin.css';

import 'tinymce/plugins/paste';
import 'tinymce/plugins/code';
import 'tinymce/plugins/link';

export default defineComponent({
  components: {
    Editor,
  },
  props: {
    modelValue: String,
  },
  setup(props, { emit }) {
    const inputvalue = computed({
      get: () => props.modelValue,
      set: (id: any) => emit('update:modelValue', id),
    });

    const init = {
      plugins: ['paste', 'link', 'textpattern', 'code'],
      skin: false,
      contextmenu: false,
      valid_elements: '*[*]',
      menubar: false,
      toolbar: 'styleselect bold italic alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link code',

      setup: function(editor: any) {
        editor.on('blur', () => {
          emit('blur');
        });
      },
    };

    return {
      inputvalue,
      init,
      tinymce,
    };
  },
});
