
import { defineComponent, ref, computed, watch, onMounted } from 'vue';
import { mdiPlus, mdiBorderAllVariant, mdiDragVariant } from '@mdi/js';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import draggable from 'vuedraggable';
import EntryRow from '../../components/Backend/Entry/EntryRow.vue';
import MediaLibButton from '../../components/Backend/MediaLib/MediaLibButton.vue';
import EntryLangSelector from '../../components/Backend/Entry/EntryLangSelector.vue';
import { DatePicker } from 'v-calendar';

export default defineComponent({
  components: {
    EntryRow,
    draggable,
    MediaLibButton,
    DatePicker,
    EntryLangSelector,
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const id = computed(() => router.currentRoute.value.params.id);
    const entries = ref<i18nEntry>({
      visible: true,
      thumbnail: -1,
      publishdate: new Date(),
    });

    const lang = ref<'de' | 'en'>('de');

    const entry = computed({
      get: () => {
        if (entries.value[lang.value]) return entries.value[lang.value] as Entry;
        return {
          title: '',
          categories: [],
          keywords: '',
          introtext: '',
          rows: [],
          author: store.getters.accountInfo.userName,
          authorpicture: -1,
          thumbnaildescription: '',
        };
      },
      set: (value: Entry) => {
        entries.value[lang.value] = value;
      },
    });

    const categories = ref([]);
    store.dispatch('fetchCategories').then((res: any) => {
      categories.value = res;
    });

    const localcategories = computed(() => {
      return categories.value.map((item: any) => {
        return {
          value: item.id,
          label: item[lang.value],
        };
      });
    });

    const del = (index: number) => {
      entry.value.rows.splice(index, 1);
    };

    if (id.value != undefined) {
      store.dispatch('fetchEntry', id.value).then((res: i18nEntry) => {
        entries.value = res;
        if (entries.value.de) lang.value = 'de';
        else if (entries.value.en) lang.value = 'en';
        if (!res.de?.rows && entries.value.de) entries.value.de.rows = [];
        if (!res.en?.rows && entries.value.en) entries.value.en.rows = [];
      });
    }
    // eslint-disable-next-line
    watch(id, (newid) => {
      if (newid != undefined) {
        store.dispatch('fetchEntry', newid).then((res: i18nEntry) => {
          entries.value = res;
          if (entries.value.de) lang.value = 'de';
          else if (entries.value.en) lang.value = 'en';
          if (!res.de?.rows && entries.value.de) entries.value.de.rows = [];
          if (!res.en?.rows && entries.value.en) entries.value.en.rows = [];
        });
      }
    });

    const showSelection = ref(false);
    const newRow = () => {
      showSelection.value = true;
    };

    const setPreviewPicture = (image: MediaEntity) => {
      entries.value.thumbnail = image.id;
    };

    const setAuthorPicture = (image: MediaEntity) => {
      entry.value.authorpicture = image.id;
    };

    const createNewRow = (template: number) => {
      switch (template) {
        case 2: // 2 Spalten Layout
          entry.value.rows.push({
            id: entry.value.rows.length,
            cols: [
              {
                width: 6,
                type: 'none',
                typeconf: {
                  text: 'THIS IS A TEXT!',
                },
              },
              {
                width: 6,
                type: 'none',
                typeconf: {
                  text: 'THIS IS A TEXT!',
                },
              },
            ],
          });

          break;
        case 3: // 3 Spalten Layout
          entry.value.rows.push({
            id: entry.value.rows.length,
            cols: [
              {
                width: 4,
                type: 'none',
                typeconf: {
                  text: 'THIS IS A TEXT!',
                },
              },
              {
                width: 4,
                type: 'none',
                typeconf: {
                  text: 'THIS IS A TEXT!',
                },
              },
              {
                width: 4,
                type: 'none',
                typeconf: {
                  text: 'THIS IS A TEXT!',
                },
              },
            ],
          });

          break;

        case 1: // 1 Spalten Layout
        default:
          entry.value.rows.push({
            id: entry.value.rows.length,
            cols: [
              {
                width: 8,
                type: 'none',
                typeconf: {
                  text: 'THIS IS A TEXT!',
                },
              },
            ],
          });
      }
      showSelection.value = false;
    };

    const save = () => {
      let error = false;
      if (entries.value.de?.title == '' && entries.value.en && entries.value.en.title == '') {
        store.dispatch('addNotification', { message: 'Bitte fügen Sie einen Titel hinzu.', type: 'error' });
        error = true;
      }
      if (entries.value.de?.introtext == '' && entries.value.en && entries.value.en.introtext == '') {
        store.dispatch('addNotification', { message: 'Bitte fügen Sie einen Kurztext hinzu.', type: 'error' });
        error = true;
      }

      if (!error) {
        store.dispatch('saveEntry', entries.value).then((res: i18nEntry) => {
          store.dispatch('addNotification', { message: 'Eintrag gespeichert' });
          router.push({ name: 'EditEntry', params: { id: res.id || 0 } });
        });
      }
    };

    const sizeChange = (change: { id: number; width: ColWidth }) => {
      console.log('change', change);
    };

    const confirmdelete = ref(false);
    const deleteEntry = () => {
      if (confirmdelete.value) {
        if (entries.value.id) {
          store.dispatch('deleteEntry', entries.value.id).then(() => {
            store.dispatch('addNotification', { message: 'Beitrag gelöscht.' });
            router.push({ name: 'OverviewEntry' });
          });
        } else {
          router.push({ name: 'OverviewEntry' });
        }
      } else {
        confirmdelete.value = true;
        setTimeout(() => (confirmdelete.value = false), 4000);
      }
    };

    const addLang = (addlang: 'de' | 'en') => {
      entries.value[addlang] = {
        title: '',
        categories: [],
        keywords: '',
        introtext: '',
        rows: [],
        author: store.getters.accountInfo.userName,
        authorpicture: -1,
        thumbnaildescription: '',
      };
      lang.value = addlang;
    };

    const switchLang = (to: 'de' | 'en') => {
      lang.value = to;
    };

    return {
      del,
      mdiPlus,
      showSelection,
      newRow,
      createNewRow,
      setPreviewPicture,
      setAuthorPicture,
      save,
      entry,
      localcategories,
      sizeChange,
      deleteEntry,
      confirmdelete,
      lang,
      entries,
      addLang,
      switchLang,
    };
  },
});
