<style lang="scss" scoped>
.langpicker {
  border-bottom: 1px solid #000;
  display: flex;
  padding: 0;
  margin-bottom: 25px;
  padding-left: 0px;
  
  div {
    margin-right: 15px;
    border: 1px solid #fff;
    border-bottom: 0;
    padding: 12px 15px;
    display: inline-block;
    letter-spacing: 2px;

    &.active {
      border: 1px solid $coral;
      border-bottom: none;
      background: $coral;
      color: #fff;
    }

    &:hover {
      background-color: $color-grey;
      border-color: $color-grey;
      cursor: pointer;
      color: #000;
    }
  }
}
.txt-sprache-waehlen {
    padding-left:20px;
    font-family: 'Helvetica Neue LT W05 96 Blk It';
  
}
</style>

<template>
  <div class="row">
    <div class="col-12">
      <div class="langpicker">
        <div @click="switchlang('de')" v-if="entry.de" :class="{ active: currentlang == 'de' }">Deutsch</div>
        <div @click="switchlang('en')" v-if="entry.en" :class="{ active: currentlang == 'en' }">Englisch</div>

        <div @click="addLang('de')" v-if="!entry.de">+ Deutsch</div>
        <div @click="addLang('en')" v-if="!entry.en">+ Englisch</div>
      </div>
      <div class="col-12" v-if="!entry.de && !entry.en">
        <p class="txt-sprache-waehlen">Bitte wählen Sie eine Sprache</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    entry: { type: Object as PropType<i18nEntry>, required: true },
    currentlang: { type: String as PropType<'de' | 'en'>, required: true },
  },
  emits: ['addlang', 'switchlang'],
  setup(props, { emit }) {
    const addLang = (lang: 'de' | 'en') => {
      emit('addlang', lang);
    };

    const switchlang = (lang: 'de' | 'en') => {
      emit('switchlang', lang);
    };

    return {
      addLang,
      switchlang,
    };
  },
});
</script>
