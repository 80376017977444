<style lang="scss">
.entry-media {
  .medialib {
    text-align: center;
    button {
      margin: 5px;
      padding: 20px;
      background-color: #eee;
      border: none;
    }

    &.previewimg {
      width: 100%;
    }
  }
  .controls {
    margin-top:5px;
    input, textarea, select {
    display:block;
    width:100%;
    border-radius: 0 !important;
    border:1px solid #eee;
    padding:.4em .5em;
    outline:none;
    font-size:100%;
    &:focus {
      border-radius: 0 !important;
      border:1px solid $font-color-std;
      box-shadow:none;
    }
  }
  }
  
}

#backend {
  .select-box {
    padding: 5px;
    display: inline-block;
    margin-top: 5px;
    select {
      padding: 3px;
      border-radius: 0;
      font-size: 90%;
    }
  }
}
</style>

<template>
  <div class="container-fluid entry-media">
    <div clas="row">
      <div class="col-12">
        <div class="medialib" v-if="!selected || selected.id == -1">
          <MediaLibButton @confirm="confirm" select>
            <button><Icon :path="mdiPlus" /><br />Medienelement einfügen</button>
          </MediaLibButton>
        </div>
        <div class="medialib previewimg" v-if="selected && selected.id != -1">
          <Media :key="selected.id" :media="selected.id" :scaling="selected.scaling" :height="selected.height" :width="selected.width" :position="selected.position" />
        </div>
      </div>
    </div>

    <div class="row" v-if="selected && selected.id != -1">
      <div class="col-6 justify-content-center text-center align-self-center">
        Skalierung
      </div>
      <div class="col-6">
        <div class="controls">
          <select v-model="selected.scaling">
            <option :value="0">Feste Größe</option>
            <option :value="1">Ausfüllen</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row" v-if="selected && selected.id != -1">
      <div class="col-6 justify-content-center text-center align-self-center">
        Breite
      </div>
      <div class="col-6">
        <div class="controls">
          <input type="text" v-model="selected.width" />
        </div>
      </div>
    </div>
    <div class="row" v-if="selected && selected.id != -1">
      <div class="col-6 justify-content-center text-center align-self-center">
        Höhe
      </div>
      <div class="col-6">
        <div class="controls">
          <input type="text" v-model="selected.height" />
        </div>
      </div>
    </div>

    <div class="row" v-if="selected && selected.id != -1 && selected.scaling == 1">
      <div class="col-6 justify-content-center text-center align-self-center">
        Positionierung
      </div>
      <div class="col-6">
        <div class="controls">
          <select v-model="selected.position">
            <option value="top left">Links Oben</option>
            <option value="top">Oben</option>
            <option value="top right">Rechts Oben</option>

            <option value="left">Links</option>
            <option value="center">Mitte</option>
            <option value="right">Rechts</option>

            <option value="bottom left">Links Unten</option>
            <option value="bottom">Unten</option>
            <option value="bottom right">Rechts Unten</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row" v-if="selected && selected.id != -1">
      <div class="col-6 justify-content-center text-center align-self-center">
        Bildunterschrift
      </div>
      <div class="col-6">
        <div class="controls">
          <input type="text" v-model="selected.description" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue';
import { mdiPlus } from '@mdi/js';
import MediaLibButton from '../MediaLib/MediaLibButton.vue';

export default defineComponent({
  props: {
    modelValue: {
      type: Object as PropType<EntryColMediaConfig>,
      required: true,
    },
  },
  components: {
    MediaLibButton,
  },
  setup(props, { emit }) {
    // const selected = ref<MediaEntity>();
    const selected = computed({
      get: () => props.modelValue,
      set: (id: any) => emit('update:modelValue', id),
    });

    const confirm = (val: MediaEntity) => {
      selected.value.id = val.id;
    };

    return {
      selected,
      mdiPlus,
      confirm,
    };
  },
});
</script>
