<style lang="scss">
$barheight: 50px;
.medialibmodalcontainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;

  background-color: rgba(0, 0, 0, 0.3);

  .medialibmodal {
    max-width: 1280px;
    position: absolute;
    background-color: #fff;
    top: 40px;
    bottom: 40px;
    left: 50%;
    margin-left: -640px;
    right: 40px;
    //padding: 30px;
    @media (max-width: 1340px) {
      max-width: 90%;
      left: 5%;
      margin-left: 0;
    }
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    .headline-h1 {
      display: none;
    }
    .medialib {
      overflow-y: scroll;
      position: absolute;
      top: $barheight;
      bottom: $barheight;
      z-index: 1;
      //border:1px solid blue;
      padding: 15px 40px;
      .selectable {
        .entity {
          border: 2px solid #fff;
        }
      }
      .selected {
        .entity {
          border: 2px solid $coral;
        }
      }
    }

    .headertab,
    .footertab {
      position: absolute;
      left: 0;
      right: 0;

      height: $barheight;
      z-index: 2;

      button {
        width: 100%;
        height: 30px;
        border: none;
        background-color: $violet;
        transition: 0.2s all;

        &:hover {
          background-color: $violet-50per;
        }
      }
    }

    .headertab {
      top: 0;
      text-align: right;

      svg {
        cursor: pointer;
        //border:1px solid red;
        position: relative;
        top: 10px;
        right: 10px;
        fill: #888;
      }
      &:hover {
        svg {
          fill: #000;
        }
      }
    }

    .footertab {
      bottom: 0;

      button {
        letter-spacing: 2px;
        color: #fff;
        height: $barheight;
      }
    }
  }
}
.medialibfade-enter-active,
.medialibfade-leave-active {
  transition: all 0.2s ease;
}

.medialibfade-enter-from,
.medialibfade-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
</style>

<template>
  <teleport to="body">
    <transition name="medialibfade">
      <div class="medialibmodalcontainer" v-if="show">
        <div class="medialibmodal">
          <div class="headertab"><Icon :path="mdiCloseBox" :height="30" :width="30" @click="closeModal" /></div>

          <MediaLib :select="select" :multiselect="multiselect" @confirm="confirmSelection" :type="type" :category="category" />
          <div class="footertab"><button @click="confirmModal" v-if="select || multiselect">Auswählen</button></div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script lang="ts">
import { Emitter } from 'mitt';

import { defineComponent, inject, ref } from 'vue';
import MediaLib from './MediaLib.vue';
import { mdiCloseBox } from '@mdi/js';

export default defineComponent({
  props: {
    select: { type: Boolean, default: true },
    multiselect: { type: Boolean, default: false },
    libid: { type: String, required: true },
    type: { type: String, default: 'all' },
    category: { type: String, default: 'all' },
  },
  components: {
    MediaLib,
  },
  emits: ['confirm'],
  setup(props, { emit }) {
    const emitter = inject('emitter') as Emitter;
    const show = ref(false);

    const confirmSelection = (entries: Array<number>) => {
      emit('confirm', entries);
    };
    // eslint-disable-next-line
    emitter.on('MediaLibShow', (e) => {
      if (e == props.libid) {
        show.value = true;
      }
    });

    const closeModal = () => {
      show.value = false;
    };

    const confirmModal = () => {
      emitter.emit('MediaLibConfirmSelection');
      closeModal();
    };

    return {
      confirmSelection,
      confirmModal,
      mdiCloseBox,
      closeModal,
      show,
    };
  },
});
</script>
