
import { Emitter } from 'mitt';
import { defineComponent, inject } from 'vue';
import MediaModal from './MediaModal.vue';
import { v4 as uuidv4 } from 'uuid';

export default defineComponent({
  props: {
    select: { type: Boolean, default: true },
    multiselect: { type: Boolean, default: false },
    type: { type: String, default: 'all' },
    category: { type: String, default: 'all' },
  },
  emits: ['confirm'],

  components: {
    MediaModal,
  },

  setup(props, { emit }) {
    const emitter = inject('emitter') as Emitter;
    const id = uuidv4();

    const openMediaLib = () => {
      emitter.emit('MediaLibShow', id);
    };

    const confirm = (entries: any) => {
      emit('confirm', entries);
    };

    return {
      id,
      confirm,
      openMediaLib,
    };
  },
});
