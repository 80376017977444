
import { defineComponent, computed, PropType } from 'vue';
import { mdiPlus } from '@mdi/js';
import MediaLibButton from '../MediaLib/MediaLibButton.vue';

export default defineComponent({
  props: {
    modelValue: {
      type: Object as PropType<EntryColMediaConfig>,
      required: true,
    },
  },
  components: {
    MediaLibButton,
  },
  setup(props, { emit }) {
    // const selected = ref<MediaEntity>();
    const selected = computed({
      get: () => props.modelValue,
      set: (id: any) => emit('update:modelValue', id),
    });

    const confirm = (val: MediaEntity) => {
      selected.value.id = val.id;
    };

    return {
      selected,
      mdiPlus,
      confirm,
    };
  },
});
