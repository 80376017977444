<style lang="scss">
.entry-media {
  .medialib {
    text-align: center;
    button {
      margin: 5px;
      padding: 20px;
      background-color: #eee;
      border: none;
    }

    &.previewimg {
      img,
      video {
        width: 100%;
        height: auto;
      }
    }
  }
}

#backend {
  .select-box {
    padding: 5px;
    display: inline-block;
    margin-top: 5px;
    select {
      padding: 3px;
      border-radius: 0;
      font-size: 90%;
    }
  }
}
</style>

<template>
  <div class="container-fluid entry-media">
    <div clas="row">
      <div class="col-12">
        <div class="medialib" v-if="!selected || selected.ids.length == 0">
          <MediaLibButton @confirm="confirm" multiselect>
            <button><Icon :path="mdiPlus" /><br />Slides auswählen</button>
          </MediaLibButton>
        </div>

        <div class="medialib previewimg" v-if="selected && selected.ids.length > 0">
          <div class="entryslider-container" :class="{ hasDescription: selected.description }">
            <Slider :items="1" :autoplay="selected.autoplay">
              <template #navigation="{left, right}" v-if="selected.arrows">
                <div class="arrow-navi">
                  <div class="arrow-btn arrow-left" @click="left">
                    <img src="@/assets/img/slider-arrow-right-bg-white.svg" alt="Pfeil Rechts" class="img-fluid" />
                  </div>
                  <div class="arrow-btn arrow-right" @click="right">
                    <img src="@/assets/img/slider-arrow-left-bg-white.svg" alt="Pfeil Links" class="img-fluid" />
                  </div>
                </div>
              </template>
              <template #items>
                <SliderItem :key="index" v-for="index in selected.ids">
                  <Media :media="index" :scaling="selected.scaling" :height="selected.height" width="100%" :position="selected.position" />
                </SliderItem>
              </template>
            </Slider>

            <!-- <Media :key="selected" :media="selected" v-for="/> -->
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="selected && selected.ids.length > 0">
      <div class="col-12 justify-content-center text-center">Autoplay <input type="checkbox" v-model="selected.autoplay" /></div>
    </div>

    <div class="row" v-if="selected && selected.ids.length > 0">
      <div class="col-12 justify-content-center text-center">Navigationspfeile <input type="checkbox" v-model="selected.arrows" /></div>
    </div>

    <div class="row" v-if="selected && selected.ids.length > 0">
      <div class="col-6 justify-content-center text-center">
        Skalierung
      </div>
      <div class="col-6">
        <div class="controls">
          <select v-model="selected.scaling">
            <option :value="0">Feste Größe</option>
            <option :value="1">Ausfüllen</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row" v-if="selected && selected.ids.length > 0">
      <div class="col-6 justify-content-center text-center">
        Höhe
      </div>
      <div class="col-6">
        <div class="controls">
          <input type="text" v-model="selected.height" />
        </div>
      </div>
    </div>

    <div class="row" v-if="selected && selected.ids.length > 0 && selected.scaling == 1">
      <div class="col-6 justify-content-center text-center">
        Positionierung
      </div>
      <div class="col-6">
        <div class="controls">
          <select v-model="selected.position">
            <option value="top left">Links Oben</option>
            <option value="top">Oben</option>
            <option value="top right">Rechts Oben</option>

            <option value="left">Links</option>
            <option value="center">Mitte</option>
            <option value="right">Rechts</option>

            <option value="bottom left">Links Unten</option>
            <option value="bottom">Unten</option>
            <option value="bottom right">Rechts Unten</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row" v-if="selected && selected.ids.length > 0">
      <div class="col-6 justify-content-center text-center align-self-center">
        Bildunterschrift
      </div>
      <div class="col-6">
        <div class="controls">
          <input type="text" v-model="selected.description" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType, ref, watch, inject } from 'vue';
import { mdiPlus, mdiTextBoxMinus } from '@mdi/js';
import MediaLibButton from '../MediaLib/MediaLibButton.vue';
import Slider from '../../Slider.vue';
import SliderItem from '../../SliderItem.vue';
import { Emitter } from 'mitt';

export default defineComponent({
  props: {
    modelValue: { type: Object as PropType<EntryColSliderConfig>, required: true },
  },
  components: {
    MediaLibButton,
    Slider,
    SliderItem,
  },
  setup(props, { emit }) {
    const mitt = inject('emitter') as Emitter;

    const selected = computed({
      get: () => props.modelValue,
      set: (id: any) => emit('update:modelValue', id),
    });

    const confirm = (val: Array<MediaEntity>) => {
      selected.value.ids = val.map((entity: MediaEntity) => entity.id);
    };

    watch(
      [selected],
      () => {
        mitt.emit('resetSlider');
      },
      { deep: true }
    );

    return {
      selected,
      mdiPlus,
      confirm,
    };
  },
});
</script>
