<style lang="scss">
#backend {
  .beitrag-erstellen {
    position: relative;
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
    background: #fff;
    $margin-std: 15px 0;
    $padding-std: 15px;

    .entry-top {
      //border:1px solid red;
      margin-bottom: 40px;
    }

    .vorschaubild-outer {
      .vorschaubild-inner {
        //border:1px solid blue;
        img {
          display: block;
          margin: 0 auto;
          max-width: 100%;
        }
      }
    }
    .authorbild-inner {
      img {
        max-width:100% !important;
        width:auto !important;
      }
    }
    .btn-zeile-erstellen {
      margin-top: 20px;
      margin-bottom: 20px;
      border: 1px dashed $font-color-std;
      color: $font-color-std;
      padding: 15px;
      width: 100%;
      text-align: center;
      border-radius: 5px;
      letter-spacing: 1px;
      text-transform: uppercase;
      cursor: pointer;
      span {
        display: inline-block;
        font-size: 150%;
      }
      svg {
        margin-left: 5px;
        position: relative;
        top: -2px;
        fill: #888;
      }
      &:hover {
        color: $font-color-hover;
        border: 1px dashed $font-color-hover;
        svg {
          fill: #333;
        }
      }
    }

    svg {
      width: 20px;
      height: 20px;
    }
    .erstelle-spalten-box {
      border: 1px dashed #888;
      border-radius: 5px;
      padding: 20px;
      margin-top: 15px;
      margin-bottom: 15px;
      .box {
        //border:1px solid red;
        padding: 15px;
        transition: $transition-std;
        &:hover {
          //background:#eee;
          cursor: pointer;
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        }
      }
      .headline {
        text-align: center;
        font-size: 90%;
        padding: 5px;
      }
      .line-box-outer {
        display: flex;

        .preview-box-inner {
          border: 1px solid #ccc;
          padding: 5px;
          width: 100%;
        }
        .preview-box-inner-2 {
          padding: 5px;
          width: 50%;
          border: 1px solid #ccc;
        }
        .preview-box-inner-3 {
          padding: 5px;
          width: 33.33%;
          border: 1px solid #ccc;
        }
      }

      .line {
        width: 100%;
        height: 3px;
        background: #ccc;
        margin: 5px 0;
      }
    }
  }
  .btn-authorenbild {
    border: 0px solid red;
    margin-top: 18px;
    padding: 0.9em 1em;
  }
  .langpicker {
    border-bottom: 1px solid #000;
    display: flex;
    padding: 0;
    margin-bottom: 25px;
    padding-left: 0px;
    div {
      margin-right: 15px;
      border: 1px solid #fff;
      border-bottom: 0;
      padding: 12px 15px;
      display: inline-block;
      letter-spacing: 2px;

      &.active {
        border: 1px solid $coral;
        border-bottom: none;
        background: $coral;
        color: #fff;
      }

      &:hover {
        background-color: $color-grey;
        border-color: $color-grey;
        cursor: pointer;
        color: #000;
      }
    }
  }
  .btn-color-custom-v1 {
    background: $mint;
    color: #333;
  }
}

.flip-list-move {
  transition: transform 0.5s;
}
</style>

<template>
  <div class="container-fluid beitrag-erstellen">
    <EntryLangSelector :entry="entries" @addlang="addLang" @switchlang="switchLang" :currentlang="lang" />
    <div class="entry-top" v-if="entries.de || entries.en">
      <div class="headline-h1">
        <h1>Teaser Informationen</h1>
        <div class="line">
          <span>Eintrag</span>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8 col-md-7 justify-content-center">
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group"><span class="label-input">Titel </span><input type="text" v-model="entry.title" /></div>
            </div>
            <div class="col-lg-6">
              <div class="form-group"><span class="label-input">Sichtbar </span><input type="checkbox" class="form-check-input" v-model="entries.visible" /></div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <span class="label-input">Kategorien</span>
                <MultiSelect :items="localcategories" v-model="entry.categories" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group"><span class="label-input">Keywords/Tags </span> <input type="text" v-model="entry.keywords" /></div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <span class="label-input">Veröffentlichungsdatum </span>
                <DatePicker v-model="entries.publishdate" mode="dateTime" is24hr locale="de" :minute-increment="5">
                  <template v-slot="{ inputValue, inputEvents }">
                    <input type="text" :value="inputValue" v-on="inputEvents" />
                  </template>
                </DatePicker>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group"><span class="label-input">Kurz-Text </span> <textarea type="text" v-model="entry.introtext">not yet implemented</textarea></div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-5 justify-content-center">
          <div class="row vorschaubild-outer">
            <div class="form-group">
              <span class="label-input">Vorschaubild </span> <MediaLibButton @confirm="setPreviewPicture" type="image"><button class="btn-std">Bibliothek öffnen</button></MediaLibButton>
            </div>
            <div class="vorschaubild-inner">
              <Media :key="entries.thumbnail" :media="entries.thumbnail" />
              <div class="form-group" v-if="entries.thumbnail && entries.thumbnail != -1">
                <span class="label-input">Beschreibung</span>
                <input type="text" v-model="entry.thumbnaildescription" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8 align-self-center">
          <div class="form-group"><span class="label-input">Author </span> <input type="text" v-model="entry.author" /></div>
        </div>
        <div class="col-lg-4 align-self-center">
          <MediaLibButton type="image" category="author" @confirm="setAuthorPicture">
            <button class="btn-std btn-authorenbild">+ Authorbild</button>
          </MediaLibButton>
          <div class="authorbild-inner">
            <Media :key="entry.authorpicture" :media="entry.authorpicture" />
          </div>
        </div>
      </div>
      <br /><br />
      <div class="headline-h1">
        <h1>Details</h1>
        <div class="line"><span>Eintrag</span></div>
      </div>

      <draggable v-model="entry.rows" item-key="id" handle=".btn-move" tag="transition-group" :component-data="{ name: 'flip-list', type: 'transition' }">
        <template #item="{element, index}">
          <EntryRow :row="element" :index="index" @delete="del(index)" @colchange="sizeChange" />
        </template>
      </draggable>

      <div class="erstelle-spalten-box" v-if="showSelection">
        <div class="row justify-content-center">
          <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4 box" @click="createNewRow(1)">
            <div class="headline">1 Spalten Layout</div>
            <div class="line-box-outer">
              <div class="preview-box-inner">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4 box" @click="createNewRow(2)">
            <div class="headline">2 Spalten Layout</div>
            <div class="line-box-outer">
              <div class="preview-box-inner-2">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
              </div>
              <div class="preview-box-inner-2">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4 box" @click="createNewRow(3)">
            <div class="headline">3 Spalten Layout</div>
            <div class="line-box-outer">
              <div class="preview-box-inner-3">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
              </div>
              <div class="preview-box-inner-3">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
              </div>
              <div class="preview-box-inner-3">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="btn-zeile-erstellen" @click="newRow" v-if="!showSelection">Zeile erstellen <Icon :path="mdiPlus" /></div>

      <div class="row justify-content-center align-self-center">
        <div class="col-lg-2 col-md-4">
          <button class="btn-std btn-color-delete" @click="deleteEntry">
            {{ confirmdelete ? 'Wirklich löschen?' : 'Löschen' }}
          </button>
        </div>
        <div class="col-lg-4 col-md-4">
          <button class="btn-std btn-color-save" @click="save">Speichern</button>
        </div>
        <!--<div class="col-lg-4 col-md-4">
          <button class="btn-std btn-color-custom-v1 text-color-grey" >Vorschau</button>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, onMounted } from 'vue';
import { mdiPlus, mdiBorderAllVariant, mdiDragVariant } from '@mdi/js';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import draggable from 'vuedraggable';
import EntryRow from '../../components/Backend/Entry/EntryRow.vue';
import MediaLibButton from '../../components/Backend/MediaLib/MediaLibButton.vue';
import EntryLangSelector from '../../components/Backend/Entry/EntryLangSelector.vue';
import { DatePicker } from 'v-calendar';

export default defineComponent({
  components: {
    EntryRow,
    draggable,
    MediaLibButton,
    DatePicker,
    EntryLangSelector,
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const id = computed(() => router.currentRoute.value.params.id);
    const entries = ref<i18nEntry>({
      visible: true,
      thumbnail: -1,
      publishdate: new Date(),
    });

    const lang = ref<'de' | 'en'>('de');

    const entry = computed({
      get: () => {
        if (entries.value[lang.value]) return entries.value[lang.value] as Entry;
        return {
          title: '',
          categories: [],
          keywords: '',
          introtext: '',
          rows: [],
          author: store.getters.accountInfo.userName,
          authorpicture: -1,
          thumbnaildescription: '',
        };
      },
      set: (value: Entry) => {
        entries.value[lang.value] = value;
      },
    });

    const categories = ref([]);
    store.dispatch('fetchCategories').then((res: any) => {
      categories.value = res;
    });

    const localcategories = computed(() => {
      return categories.value.map((item: any) => {
        return {
          value: item.id,
          label: item[lang.value],
        };
      });
    });

    const del = (index: number) => {
      entry.value.rows.splice(index, 1);
    };

    if (id.value != undefined) {
      store.dispatch('fetchEntry', id.value).then((res: i18nEntry) => {
        entries.value = res;
        if (entries.value.de) lang.value = 'de';
        else if (entries.value.en) lang.value = 'en';
        if (!res.de?.rows && entries.value.de) entries.value.de.rows = [];
        if (!res.en?.rows && entries.value.en) entries.value.en.rows = [];
      });
    }
    // eslint-disable-next-line
    watch(id, (newid) => {
      if (newid != undefined) {
        store.dispatch('fetchEntry', newid).then((res: i18nEntry) => {
          entries.value = res;
          if (entries.value.de) lang.value = 'de';
          else if (entries.value.en) lang.value = 'en';
          if (!res.de?.rows && entries.value.de) entries.value.de.rows = [];
          if (!res.en?.rows && entries.value.en) entries.value.en.rows = [];
        });
      }
    });

    const showSelection = ref(false);
    const newRow = () => {
      showSelection.value = true;
    };

    const setPreviewPicture = (image: MediaEntity) => {
      entries.value.thumbnail = image.id;
    };

    const setAuthorPicture = (image: MediaEntity) => {
      entry.value.authorpicture = image.id;
    };

    const createNewRow = (template: number) => {
      switch (template) {
        case 2: // 2 Spalten Layout
          entry.value.rows.push({
            id: entry.value.rows.length,
            cols: [
              {
                width: 6,
                type: 'none',
                typeconf: {
                  text: 'THIS IS A TEXT!',
                },
              },
              {
                width: 6,
                type: 'none',
                typeconf: {
                  text: 'THIS IS A TEXT!',
                },
              },
            ],
          });

          break;
        case 3: // 3 Spalten Layout
          entry.value.rows.push({
            id: entry.value.rows.length,
            cols: [
              {
                width: 4,
                type: 'none',
                typeconf: {
                  text: 'THIS IS A TEXT!',
                },
              },
              {
                width: 4,
                type: 'none',
                typeconf: {
                  text: 'THIS IS A TEXT!',
                },
              },
              {
                width: 4,
                type: 'none',
                typeconf: {
                  text: 'THIS IS A TEXT!',
                },
              },
            ],
          });

          break;

        case 1: // 1 Spalten Layout
        default:
          entry.value.rows.push({
            id: entry.value.rows.length,
            cols: [
              {
                width: 8,
                type: 'none',
                typeconf: {
                  text: 'THIS IS A TEXT!',
                },
              },
            ],
          });
      }
      showSelection.value = false;
    };

    const save = () => {
      let error = false;
      if (entries.value.de?.title == '' && entries.value.en && entries.value.en.title == '') {
        store.dispatch('addNotification', { message: 'Bitte fügen Sie einen Titel hinzu.', type: 'error' });
        error = true;
      }
      if (entries.value.de?.introtext == '' && entries.value.en && entries.value.en.introtext == '') {
        store.dispatch('addNotification', { message: 'Bitte fügen Sie einen Kurztext hinzu.', type: 'error' });
        error = true;
      }

      if (!error) {
        store.dispatch('saveEntry', entries.value).then((res: i18nEntry) => {
          store.dispatch('addNotification', { message: 'Eintrag gespeichert' });
          router.push({ name: 'EditEntry', params: { id: res.id || 0 } });
        });
      }
    };

    const sizeChange = (change: { id: number; width: ColWidth }) => {
      console.log('change', change);
    };

    const confirmdelete = ref(false);
    const deleteEntry = () => {
      if (confirmdelete.value) {
        if (entries.value.id) {
          store.dispatch('deleteEntry', entries.value.id).then(() => {
            store.dispatch('addNotification', { message: 'Beitrag gelöscht.' });
            router.push({ name: 'OverviewEntry' });
          });
        } else {
          router.push({ name: 'OverviewEntry' });
        }
      } else {
        confirmdelete.value = true;
        setTimeout(() => (confirmdelete.value = false), 4000);
      }
    };

    const addLang = (addlang: 'de' | 'en') => {
      entries.value[addlang] = {
        title: '',
        categories: [],
        keywords: '',
        introtext: '',
        rows: [],
        author: store.getters.accountInfo.userName,
        authorpicture: -1,
        thumbnaildescription: '',
      };
      lang.value = addlang;
    };

    const switchLang = (to: 'de' | 'en') => {
      lang.value = to;
    };

    return {
      del,
      mdiPlus,
      showSelection,
      newRow,
      createNewRow,
      setPreviewPicture,
      setAuthorPicture,
      save,
      entry,
      localcategories,
      sizeChange,
      deleteEntry,
      confirmdelete,
      lang,
      entries,
      addLang,
      switchLang,
    };
  },
});
</script>
