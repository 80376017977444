<style lang="scss" scoped>
$margin-std: 15px 0;
$padding-std: 15px;
$color-transparent: transparent;
$color-grey: #ccc;
$font-color-std: #888;

.layout {
  border: 1px solid #eee;
  padding: 5px;
  margin: $margin-std;

  //padding:$padding-std;
  .row {
    margin: 0;
    border: 1px solid $color-transparent;
    &:hover {
      //border:1px dashed $color-grey;
    }
    [class*='col-'] {
      border: 1px solid $color-transparent;
      &:hover {
        border: 1px dashed $color-grey;
      }
    }
  }
  .type-edit-outer {
    .type-edit-inner {
      &:hover {
      }
    }
  }
}

.sortable-chosen {
  background: rgba($color: $yellow, $alpha: 0.5);
  border: 1px solid $yellow;
}

$font-color-std: #888;
$font-color-hover: #333;
.controls {
  background: #eee;
  padding: 10px;
  display: flex;
  
  @media (max-width: $width-lg) {
    text-align: center;
    display: block;
    padding: 12px 5px 5px 5px;
  }
 
  .control-box {
    width: 50%;
    display: flex;
    //border:1px solid red;
    @media (max-width: $width-lg) {
      width: 100%;
      display: block;
    }
    

  }
  .form-select {
    display: inline-block;
    width: auto;
    margin-right: 8px;
    line-height: 1.2;
    text-align: center;
    font-size: 14px;
    border: 0;
    border:1px solid blue;
    &:focus {
      box-shadow: none;
    }
  }
  .btn {
    color: $font-color-std;
    //border:1px solid red;
    padding: 0.2em 0.5em;
    margin-left: 10px;
    letter-spacing: 0.5px;
    span {
      font-size: 14px;
    }
    svg {
      margin-left: 5px;
      fill: $font-color-std;
      position: relative;
      top: -1px;
    }
    &:hover {
      color: $font-color-hover;
      svg {
        fill: $font-color-hover;
      }
    }
  }

  .btn-delete,
  .btn-move {
    svg {
      transform: rotate(45deg);
    }
  }

  .btn-move {
    cursor: move;
  }
}
.width-small {
  width: 50% !important;
  margin: 15px auto 0 auto !important;
  display: inline-block;
  @media (max-width: $width-md) {
    width: 100% !important;
  }
}

.type-edit-outer {
  border: 0px solid red;

  .type-edit-inner {
    padding: 8px 0;
    margin-bottom: 5px;
  }
  .type-edit {
    font-style: italic;
    font-size: 14px;
    text-align: center;
    letter-spacing: 1px;
    color: $font-color-std;
    cursor: pointer;
    &:hover {
      color: #333;
    }
  }
}

.create-type {
  text-align: center;
  button {
    margin: 5px;
    padding: 15px;
    background-color: #eee;
    border: none;
    width: 80px;
    transition: $transition-std;
    color: #555;
    svg {
      fill: #555;
    }
    span {
      display: block;
      letter-spacing: 1px;
      margin-top: 3px;
      font-size: 90%;
    }
    &:hover {
      background: #ddd;
    }
  }
}
</style>

<template>
  <div class="layout layout-12" ref="element">
    <div class="controls">
      <div class="control-box">
        <select class="form-select" v-model="width" v-if="false && row.cols.length == 1">
          <option :value="12" selected>Spalten Breite 100%</option>
          <option :value="9">Spalten Breite 75%</option>
          <option :value="6">Spalten Breite 50%</option>
        </select>
      </div>
      <div class="control-box justify-content-end">
        <!--<div class="btn btn-border" title="Zeile löschen"><span>Border anzeigen</span><Icon :path="mdiBorderAllVariant" /></div>-->
        <div class="btn btn-delete" title="Zeile löschen" @click="del"><span>Zeile löschen</span><Icon :path="mdiPlus" /></div>
        <div class="btn btn-move" title="Zeile verschieben"><span>Zeile verschieben</span><Icon :path="mdiDragVariant" /></div>
      </div>
    </div>
    <div class="row justify-content-center type-edit-outer">
      <div :class="`col-lg-${col.width}`" :key="index" v-for="(col, index) in row.cols" class="align-self-center">
        <div class="type-edit-inner">
          <div class="type-edit" @click="setType(col, 'none')">Type ändern</div>
        </div>
        <p v-if="col.type == 'text'">
          <EntryText v-model="col.typeconf.text" />
        </p>
        <p v-if="col.type == 'media'">
          <EntryMedia v-model="col.typeconf" />
        </p>
        <p v-if="col.type == 'slider'" class="text-center">
          <EntrySlider v-model="col.typeconf" />
          <!-- <button class="btn-std width-small">Slider Einfügen</button> -->
        </p>

        <p class="create-type" v-if="!col.type || col.type == 'none'">
          <button @click="setType(col, 'text')"><Icon :path="mdiPlus" /> <span>Text</span></button>
          <button @click="setType(col, 'media')"><Icon :path="mdiPlus" /><span>Media</span></button>
          <button @click="setType(col, 'slider')"><Icon :path="mdiPlus" /><span>Slider</span></button>
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, PropType, watch } from 'vue';
import { mdiPlus, mdiBorderAllVariant, mdiDragVariant } from '@mdi/js';

import EntryText from './EntryText.vue';
import EntryMedia from './EntryMedia.vue';
import EntrySlider from './EntrySlider.vue';

export default defineComponent({
  components: {
    EntryText,
    EntryMedia,
    EntrySlider,
  },
  props: {
    row: { type: Object as PropType<EntryRow>, required: true },
    index: { type: Number },
  },
  setup(props, { emit }) {
    const element = ref(null);
    const width = ref(12);

    watch(props.row, () => {
      const { row } = props;
      if (row.cols.length == 1) {
        width.value = row.cols[0].width;
      }
    });

    watch(width, (oldval: number, newval: number) => {
      console.log(oldval, newval);
      if (oldval != newval) emit('colchange', { id: props.row.id, width: newval });
    });

    const del = () => {
      emit('delete', element);
    };

    const setType = (col: EntryCol, type: string) => {
      switch (type) {
        case 'text':
          col.type = 'text';
          col.typeconf = {
            text: '',
          };
          break;
        case 'media':
          col.type = 'media';
          col.typeconf = {
            id: -1,
            scaling: 0,
            width: '100%',
            height: 'auto',
            position: 'center',
            description: '',
          };
          break;
        case 'slider':
          col.type = 'slider';
          col.typeconf = {
            ids: [],
            height: 'auto',
            scaling: 0,
            position: 'center',
          };
          break;
        case 'none':
          col.type = 'none';
          col.typeconf = {
            id: -1,
          };
      }
    };
    return {
      element,
      del,
      mdiPlus,
      mdiBorderAllVariant,
      mdiDragVariant,
      setType,

      width,
    };
  },
});
</script>
