<style lang="scss">
.mce-content-body[contentEditable='true'] {
  outline: 1px dashed #000 !important;
}
.mce-content-body[contentEditable='true']:focus,
.mce-content-body[contentEditable='true']:hover {
  outline: 1px dashed #000 !important;
}

p {
  a {
    //border:1px solid blue;
    border-bottom: 1px solid #000;
    &:hover {
      color: #000;
      opacity: 0.7;
    }
  }
}
</style>

<template>
  <Editor :init="init" v-model="inputvalue" :inline="true" />
</template>

<script lang="ts">
/* eslint-disable */
import { defineComponent, computed } from 'vue';
import Editor from '@tinymce/tinymce-vue';
import tinymce from 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/skins/ui/oxide/skin.css';

import 'tinymce/plugins/paste';
import 'tinymce/plugins/code';
import 'tinymce/plugins/link';

export default defineComponent({
  components: {
    Editor,
  },
  props: {
    modelValue: String,
  },
  setup(props, { emit }) {
    const inputvalue = computed({
      get: () => props.modelValue,
      set: (id: any) => emit('update:modelValue', id),
    });

    const init = {
      plugins: ['paste', 'link', 'textpattern', 'code'],
      skin: false,
      contextmenu: false,
      valid_elements: '*[*]',
      menubar: false,
      toolbar: 'styleselect bold italic alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link code',

      setup: function(editor: any) {
        editor.on('blur', () => {
          emit('blur');
        });
      },
    };

    return {
      inputvalue,
      init,
      tinymce,
    };
  },
});
</script>
